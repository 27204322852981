export default function auhtHeader() {
    const storedUser = localStorage.getItem('user');
    let user = JSON.parse(storedUser ? storedUser : "");

    if (user && user.token) {
      //console.log({ Authorization: 'Bearer ' + user.token, "Content-type": "application/json","Access-Control-Allow-Origin": "*" })
        return { Authorization: 'Bearer ' + user.token, "Content-type": "application/json","Access-Control-Allow-Origin": "*" }; // for Spring Boot back-end
        //return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
      } else {
        return {};
      }
}