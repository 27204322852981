import { IBranch } from "@/models/IBranch";
import { IResponse } from "@/models/IClient";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class BranchService {
  branch!: IBranch;
  lstBranch: IBranch[] = [];
  responsedata: any;

  saveBranch(model: IBranch): Promise<IResponse> {
    model.userId = userId;
    model.clientId = clientId;

    this.responsedata = axios
      .post<IResponse>(API_URL + "Branch/saveBranch", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getListofBranch(
    pageIndex: number,
    search: string = ""
  ): Result {
    var URL =
      API_URL + "Branch/getbranchbyclientid/" + clientId + "/" + pageIndex;

    if (search.length > 0) {
      URL =
        API_URL +
        "Branch/getbranchbyclientid/" +
        clientId +
        "/" +
        pageIndex +
        "?search=" +
        search;
    }

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader() })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getAllBranch(): IBranch[] {
    this.responsedata = axios
      .get<IResponse>(API_URL + "branch/getallBranchByClientId/" + clientId, {
        headers: auhtHeader(),
      })
      .then((response) => {
        this.lstBranch = response.data.result;
        return this.lstBranch;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getBranchByBranchId(branchId: number): Promise<IBranch> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "Branch/getbranchbybranchid?branchId=" +
          branchId +
          "&clientId=" +
          clientId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new BranchService();
