import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { IBranch } from "@/models/IBranch";
import BranchService from "@/services/BranchService";
import { Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Options({
  components: {
    AdminLayout,
    ValidationObserver
  },
})
export default class ClientModule extends Vue {
  active = false;
  loading = false;
  isloaded = false
  userId = 0;
  branches:any
  totalItems = 0
  current_page = 1
  search: string = ""
  refForm: any = (this as any).$refs.form;

  $refs!: {
    validationProviderRef: InstanceType<typeof ValidationProvider>,
    validationObserverRef: InstanceType<typeof ValidationObserver>
}

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  branch: IBranch = {
    branchId: 0,
    branchName: "",
    address: "",
    phoneNo: "",
    clientId: 1,
    userId: 0,
  };

  async created() {
    var result = await BranchService.getListofBranch(1)
    this.branches = result.items
    this.isloaded = true
    this.totalItems = result.totalPages
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId
    this.branch.userId = this.userId;
  }

  handleChangePage(page) {
    this.table.page = page - 1;
    //this.isloaded = false;
    //this.setTable(this.table.page);
  }

  async setTable(pagenumber:number)
  {
    this.branches = []
    var result = await BranchService.getListofBranch(pagenumber,this.search)
    this.branches = result.items
   // this.totalItems = result.totalCount
    //console.log(this.clients)
   // this.totalItems = this.clients.length;
  }

  @Watch("branches")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true
    console.log(this.branches)
    console.log(this.isloaded)
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page)
    //console.log(this.current_page)
  }

  async handleSearch(searching) {
    if (searching.length > 3)
    {
      this.search = searching
      var result = await BranchService.getListofBranch(1,this.search)
      this.branches = result.items
    }
    else if(searching.length == 0)
    {
      var result = await BranchService.getListofBranch(1)
      this.branches = result.items
    }
  }

  onBack()
  {
    //this.setTable(1)
    this.active = !this.active;
  }

  async onEdit(branchId:number)
  {
    this.branch = await BranchService.getBranchByBranchId(branchId)
    this.active = !this.active
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;
    this.branch.userId = this.userId;
    BranchService.saveBranch(this.branch).then(
      (data) => {
        this.loading = false;
        this.setTable(1);
        (this as any).$vs.notify({
          title: "New Branch",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        this.branch = {
          branchId: 0,
          branchName: "",
          address: "",
          phoneNo: "",
          clientId: 1,
          userId: this.userId,
        };
        (this as any).$refs.observer.reset()
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New Client",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
